<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/menu/MenuList')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
      <div style="    text-align: right;margin-right: 70px;">{{form.status_text}}</div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="菜单名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
  
        <el-form-item label="是否显示在菜单栏">
          <el-switch id="huixian" v-model="form.is_show" ></el-switch>
        </el-form-item>

        <el-form-item label="上级菜单">
          <el-cascader
            v-model="form.p_id"
            :options="options"
            :props="{ checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>

        <el-form-item label="路由地址">
          <el-input  v-model="form.url"></el-input>
        </el-form-item>

        <el-form-item label="显示排序">
          <el-input  v-model="form.sort"></el-input>
        </el-form-item>
        
        <el-form-item label="图标">
          <el-input  v-model="form.icon"></el-input>
        </el-form-item>



     <el-form-item label="绑定接口">
      <el-select v-model="org_id"  multiple placeholder="请选择">
        <el-option
          v-for="item in optionss"
          :key="item.org_id"
          :label="item.name"
          :value="item.org_id">
        </el-option>
      </el-select>
      <el-button style="margin-left:10px;" type="primary" @click="bindOrg">绑定</el-button>
    </el-form-item>
  
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确认</el-button>
        <el-button @click="goNav('/menu/MenuList')" >取消</el-button>
      </el-form-item>
    </el-form>
</div>


      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          name: '',
          is_show: 0,
          p_id: 0,
          url: '',
          sort: 1,
          icon: '',
        },
        options:[],
        optionss:[],
        org_id:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      if(this.form.p_id[0]){
        postdata.p_id = this.form.p_id[this.form.p_id.length-1];
      }
      if(postdata.p_id == 13){
        postdata.p_id = 0;
      }
      if(postdata.is_show == 0){
        postdata.is_show = 2;
      }else{
        postdata.is_show = 1;
      }
      axios.post(config.menu,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '编辑成功',
                type: 'success'
              });
              _this.goNav('/menu/MenuList');
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
      bindOrg(){
        let _this = this;
        let postdata ={
          org_id:this.org_id+'',
          menu_id:this.form.menu_id+''
        }
      axios.post(config.bindOrg,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
    init(a){
      let _this = this;
        let obj = JSON.parse(a);
        if(obj.is_show == 2){
            obj.is_show = 0;
        }else{
            let inter = setInterval(function(){
                if(document.getElementById('huixian')){
                    document.getElementById('huixian').click();
                    clearInterval(inter);
                }
            },1000);
        }
        if(obj.p_id == 0){
            obj.p_id = 13;
        }
        this.form = obj;
        //回显
        axios.get(config.bindOrgList,{params:{menu_id:obj.menu_id}})
          .then(function (response) {
              if(response.data.code == 200){
                _this.org_id = response.data.data.org_id;
              }
            })
          .catch(function (error) {
            console.log(error);
          });
        
    },
    initoptions(){
         let _this = this;
        axios.get(config.menu,{params:{page:1}})
        .then(function (response) {
            if(response.data.code == 200){
              fiflter(response.data.data);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        axios.get(config.route,{params:{query:'',page:1,limit:9999}})
        .then(function (response) {
            if(response.data.code == 200){
              let list = response.data.data.data
              for(let i in list){
                let obj= {
                    org_id:list[i].org_id,
                    name:list[i].name,
                }
              _this.optionss.push(obj);
              }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      function fiflter(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].menu_id;
          if(list[i]._child){
            obj.children = dg(list[i]._child);
          }
          arr.push(obj);
         }
          _this.options = arr;
        }
      function dg(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].menu_id;
          if(list[i]._child){
            obj.children = dg(list[i]._child);
          }
          arr.push(obj);
        }
        return arr;
      }
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
      this.initoptions();
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
